.switch-wrapper {
    font-size: 4px;
    user-select: none;
    box-sizing: border-box;
    margin-bottom: 2px;
}

.dark-mode-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dark-mode-switch .text{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-right: 10px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: -0.05px;
}

.dark-mode-switch {
    margin-right: 10px;
}

.switch-wrapper label {
    display: block;
}

.switch {
    position: relative;
    z-index: 0;
    display: block;
    width: 14em;
    height: 8em;
    background-color: #BFE6F7;
    border-width: 0.5em;
    border-style: solid;
    border-color: #80C1D7;
    border-radius: 10em;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.circle {
    position: absolute;
    z-index: 20;
    top: 0.5em;
    right: 0.5em;
    border-width: 0.5em;
    width: 6em;
    height: 6em;
    background-color: #F5ED28;
    border: 0.5em solid #E6C542;
    border-radius: 7em;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.cloud {
    position: absolute;
    top: 3em;
    left: 3em;
    width: 6em;
    heigth: 6em;
    transition: all 0.3s ease-in-out;
    -webkit-animation: cloud 12s linear infinite;
    -moz-animation: cloud 12s linear infinite;
    -o-animation: cloud 12s linear infinite;
    animation: cloud 12s linear infinite;
}

.cloud div,
.cloud div:before,
.cloud div:after {
    position: absolute;
    display: block;
    z-index: 40;
    width: 4em;
    height: 2em;
    background-color: #F5F6F8;
    border-radius: 1em;
    content: "";
}

.cloud div:before {
    bottom: 1em;
    left: 0.5em;
    width: 2em;
    height: 2em;
}

.cloud div:after {
    bottom: 1em;
    left: 2em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.75em;
}

.cloud div + div {
    top: -0.5em;
    left: -0.5em;
}

.cloud div + div,
.cloud div + div:before,
.cloud div + div:after {
    z-index: 30;
    width: 5em;
    height: 3em;
    background-color: #D2D4D3;
    border-radius: 1.5em;
}

.cloud div + div:before {
    bottom: 1em;
    left: 0.5em;
    width: 3em;
    height: 3em;
}

.cloud div + div:after {
    bottom: 1em;
    left: 2em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes cloud {
    0% {
        left: -8em;
    }
    100% {
        left: 16em;
    }
}

@-moz-keyframes cloud {
    0% {
        left: -8em;
    }
    100% {
        left: 16em;
    }
}

@-o-keyframes cloud {
    0% {
        left: -8em;
    }
    100% {
        left: 16em;
    }
}

@keyframes cloud {
    0% {
        left: -8em;
    }
    100% {
        left: 16em;
    }
}

[not-existing] {
    zoom: 1;
}

.switch-wrapper input[type="checkbox"] {
    display: none;
}

.switch-wrapper input[type="checkbox"]:checked + .switch {
    background-color: #484848;
    border-color: #202020;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .circle {
    right: 6.5em;
    background-color: #FFFDF1;
    border-color: #E1E5C4;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots {
    width: 2em;
    height: 2em;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots,
.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots:before,
.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots:after {
    border-width: 0.5em;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots:before {
    height: 0.5em;
    width: 0.5em;
    border-radius: 1em;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .moon-spots:after {
    height: 0.33333333em;
    width: 0.33333333em;
    border-radius: 0.66666667em;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .cloud {
    opacity: 0;
}

.switch-wrapper input[type="checkbox"]:checked + .switch .stars {
    opacity: 1;
}

.moon-spots {
    top: 0.35714286em;
    right: 0.35714286em;
    z-index: 10;
}

.moon-spots,
.moon-spots:before,
.moon-spots:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    background-color: #EFEED9;
    border: 0 solid #E1E5C4;
    border-color: #E1E5C4;
    border-radius: 50%;
    content: "";
    transition: all 0.3s ease-in-out;
}

.moon-spots:before {
    right: 3em;
}

.moon-spots:after {
    right: 0.5em;
    bottom: -3em;
}

.stars {
    position: absolute;
    top: 0;
    right: 0;
    width: 6.5em;
    height: 7em;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.stars div {
    bottom: 1em;
    left: 0;
    width: 0.65em;
    height: 0.65em;
}

.stars div,
.stars div:before,
.stars div:after {
    position: absolute;
    display: block;
    background-color: #FEFEFE;
    border-radius: 50%;
    content: "";
}

.stars div:before {
    bottom: 3.5em;
    left: 1.5em;
    width: 0.3125em;
    height: 0.3125em;
}

.stars div:after {
    bottom: 5em;
    left: -0.5em;
    width: 0.5em;
    height: 0.5em;
}

.stars div + div {
    top: 3em;
    left: 5em;
    right: 0;
}

.stars div + div:before {
    bottom: -1em;
    left: -2em;
}

.stars div + div:after {
    bottom: -2em;
    left: -0.25em;
}